@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
#root > div:first-child,
.h-full-screen {
  min-height: 100vh;
}

.dangerouslySetInnerHTML {
  table {
    th, td {
      border: 1px solid #E5E7EB;
      padding: 2px 8px;
    }
    th { 
      background-color: #f9fafb;
      font-weight: normal !important;
    }
    td {
      font-style: italic;
      font-size: 14px;
    }
  }
}
.statusColor {
  .Completed, .Success, .complete {
    background-color: #d4edda;
  }
  .Failed,
  .Cancelled,
  .Rejected,
  .overdue {
    background-color: #ffc0cb;
  }
  .Request,
  .Pending,
  .active {
    background-color: #cce5ff;
  }
}

.confirmModalLoanChange {
  .loanChangeCancelRequest {
    display: none !important;
  }
}

.shadow1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.hover-shadow1 {
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}

.btn-link {
  @apply text-xs text-shade-blue hover:underline cursor-pointer;
}

.btn-icon {
  @apply cursor-pointer text-shade-blue hover-shadow1 p-1 rounded;
}

.header-profile-name {
  margin-right: -21px !important;
}

.input2read-label {
  width: calc(100% - 20px);
}

@font-face {
  font-family: work-sans;
  src: url(https://fonts.gstatic.com/s/worksans/v17/QGYsz_wNahGAdqQ43Rh_fKDp.woff2);
}

.main {
  // min-height: calc(100vh - 426px);
  color: #37393b !important;
  font-family: "Work Sans";
}

.font-variation-settings-400 {
  font-variation-settings: "wght" 400;
}

.font-variation-settings-500 {
  font-variation-settings: "wght" 500;
}

.font-variation-settings-600 {
  font-variation-settings: "wght" 600;
}

.font-variation-settings-700 {
  font-variation-settings: "wght" 700;
}

.font-variation-settings-800 {
  font-variation-settings: "wght" 800;
}

.NotReviewed {
  background-color: #ffffff;
}
.Approved {
  background-color: #d4edda;
}
.Inactive {
  background-color: #e2e3e5;
}
.Incomplete {
  background-color: #f8d7da;
}
.MissingPages {
  background-color: #ffc0cb;
}
.Unacceptable {
  background-color: #fff3cd;
}
.Duplicate {
  background-color: #fdf5e6;
}
.Illegible {
  background-color: #faebd7;
}
.Reviewed {
  background-color: #cce5ff;
}

@font-face {
  font-family: 'freehand';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/freehand/v27/cIf-Ma5eqk01VjKTgDmNRGQ-mQ.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200C, U+25CC;
}

@font-face {
  font-family: 'freehand';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/freehand/v27/cIf-Ma5eqk01VjKTgDmGRGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

input[type=file]::file-selector-button {
  background-color: #6b7280 !important;
  border-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.input-code {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;     /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}

.w-full-tooltip-inline {
  .w-fit {
    width: full !important;
    display: inline !important;
  }
}